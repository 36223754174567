<template>
  <b-container fluid class="footer">
    <footer>
      <b-row class="inner">
        <b-col cols="12" md="8">
          <p style="font-size:14px; font-weight:600;">©2021 Ryan Howerter. All rights reserved. Site designed and developed by Ryan Howerter using Vue.js & Bootstrap-Vue.</p>
        </b-col>
        <b-col cols="12" md="4" class="socials">
          <a class="green" href="https://www.instagram.com/ryan_howerter"><font-awesome-icon :icon="['fab', 'instagram']" size="lg" /></a>&nbsp;
          <a class="purple" href="https://www.linkedin.com/in/ryan-howerter-72805991"><font-awesome-icon :icon="['fab', 'linkedin']" size="lg" /></a>&nbsp;
          <a class="blue" href="https://www.flickr.com/photos/ltdemartinet/"><font-awesome-icon :icon="['fab', 'flickr']" size="lg" /></a>&nbsp;
          <a class="yellow" href="mailto:ryanhowerter@gmail.com"><font-awesome-icon :icon="['fas', 'envelope']" size="lg" /></a>
        </b-col>
      </b-row>
    </footer>
  </b-container>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    msg: String
  },
}
</script>


<style lang="scss">
@media (min-width: 541px) {
  .socials {
    text-align: right;
  }
}
@media (max-width: 540px) {
  .socials {
    text-align: left;
  }
}
.footer {
  background-color: black;
}
.inner {
  padding:15px 15px 5px 15px;
}
b-icon {
  padding: 10px;
  color: #98f8b3;
}
b-icon:hover {
  color: white;
}
a.green:hover {
  color:#98f8be !important;
}
a.purple:hover {
  color:#ec4fdf !important;
}
a.blue:hover {
  color:#93f1ff !important;
}
a.yellow:hover {
  color:#ffef6e !important;
}
.footer p {
  color:white;
}

</style>
