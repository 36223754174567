<template>
  <div id="app">
    <Header class="fixed-top"></Header>
    <div class="not-header">
    <router-view/>
    <Footer></Footer>
  </div>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  metaInfo: {
    title: 'ryansite Us'
  }
}
</script>

<style lang="scss">

.not-header {
  padding-top: 56px;
}

</style>
