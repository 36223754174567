<template>
	<div class="design-page">
		<b-container class="mainrow">

			<b-row class="titlerow d-block d-md-none" style="margin:auto;">
				<b-col cols="12"><b-img fluid center :src="require('../assets/design.png')" alt="design title"></b-img></b-col>
			</b-row>

			<b-row class="itemrow" style="margin:auto;">
				<b-col cols="12" md="6">
					<b-img fluid left :src="require('../assets/line.png')" alt="line graphic"></b-img>
				<br/>
					<p>A poster I created for my own band's debut concert. I scanned our heads using Bellus3D, and composited the scene in Blender.</p>
					<p style="font-size:12px;">Hangul Regular font by Sébastien Hayez via Velvetyne Type Foundry</p>
				</b-col>
				<b-col cols="12" md="6">
					<b-img class="shadow" fluid center :src="require('../assets/strangeloop.jpg')" alt="Strange Loop concert poster"></b-img>
				</b-col>
			</b-row>

			<b-row class="itemrow" style="margin:auto;">
				<b-col cols="12" md="6">
					<b-img fluid center :src="require('../assets/line.png')" alt="line graphic"></b-img>
				<br/>
					<p>A landing page created for a B2B client's SAAS product.</p>
					<p style="font-size:12px;">Created for and under employment by Madwire<sup>®</sup></p>
				</b-col>
				<b-col cols="12" md="6">
					<b-img class="shadow" fluid center :src="require('../assets/NexusHomepage.jpg')" alt="Nexus landing page mockup"></b-img>
				</b-col>
			</b-row>

			<b-row class="itemrow" style="margin:auto;">
				<b-col cols="12" md="6">
					<b-img fluid center :src="require('../assets/line.png')" alt="line graphic"></b-img>
				<br/>
					<p>A website created for a CBD client. I used a combination of earthy, natural textures and the client's own images to create a friendly atmosphere and highlight the quality of their products.</p>
					<p style="font-size:12px;">Created for and under employment by Madwire<sup>®</sup></p>
				</b-col>
				<b-col cols="12" md="6">
					<b-img class="shadow" fluid center :src="require('../assets/islandtree.jpg')" alt="Island Tree website mockup"></b-img>
				</b-col>
			</b-row>

			<b-row class="itemrow" style="margin:auto;">
				<b-col cols="12" md="6">
					<b-img fluid center :src="require('../assets/line.png')" alt="line graphic"></b-img>
				<br/>
					<p>A poster designed for both print and social media to increase awareness and participation for a local event</p>
				</b-col>
				<b-col cols="12" md="6">
					<b-img class="shadow" fluid center :src="require('../assets/soup.jpg')" alt="Soup Dupe & Scoop poster"></b-img>
				</b-col>
			</b-row>

			<b-row class="itemrow" style="margin:auto;">
				<b-col cols="12" md="6">
					<b-img fluid center :src="require('../assets/line.png')" alt="line graphic"></b-img>
				<br/>
					<p>A website created for an athletic physical therapy client, with a focus on recovery and accessibility.</p>
					<p style="font-size:12px;">Created for and under employment by Madwire<sup>®</sup></p>
				</b-col>
				<b-col cols="12" md="6">
					<b-img class="shadow" fluid center :src="require('../assets/highkey.jpg')" alt="Highkey Balance website mockup"></b-img>
				</b-col>
			</b-row>

			<b-row class="itemrow" style="margin:auto;">
				<b-col cols="12" md="6">
					<b-img fluid center :src="require('../assets/line.png')" alt="line graphic"></b-img>
				<br/>
					<p>A set of generic social media templates with a foggy forest atmosphere.</p>
					<p style="font-size:12px;">Created for and under employment by Madwire<sup>®</sup></p>
				</b-col>
				<b-col cols="12" md="6">
					<b-img class="shadow" fluid center :src="require('../assets/forestfog.jpg')" alt="Social media templates"></b-img>
				</b-col>
			</b-row>

			<b-row class="itemrow" style="margin:auto;">
				<b-col cols="12" md="6">
					<b-img fluid center :src="require('../assets/line.png')" alt="line graphic"></b-img>
				<br/>
					<p>Another poster created for a small event in Boulder.</p>
					<p style="font-size:12px;"></p>
				</b-col>
				<b-col cols="12" md="6">
					<b-img class="shadow" fluid center :src="require('../assets/picnic.jpg')" alt="Picnic in the Park poster"></b-img>
				</b-col>
			</b-row>

			<b-row class="itemrow" style="margin:auto;">
				<b-col cols="12" md="6">
					<b-img fluid center :src="require('../assets/line.png')" alt="line graphic"></b-img>
				<br/>
					<p>A homepage mockup for a healthcare client. I wanted to feature their logo in a unique way, giving their brand both a human softness and a strong sense of professionalism.</p>
					<p style="font-size:12px;">Created for and under employment by Madwire<sup>®</sup></p>
				</b-col>
				<b-col cols="12" md="6">
					<b-img class="shadow" fluid center :src="require('../assets/RxBluHomepage.png')" alt="RxBlu homepage mockup"></b-img>
				</b-col>
			</b-row>

		</b-container>
	</div>
</template>

<script>

export default {
	name: 'Design',
};

</script>

<style lang="scss">
.design-page {
	background-image: url('../assets/bg F.jpg');
	background-attachment: fixed;
	background-position: top center;
	background-size: cover;
}
.design-page {
a {
	color:#71e09d !important;
}
.titlerow {
	padding: 20px;
}
.itemrow {
	padding-top: 40px;
	padding-bottom: 40px;
}

	@media (min-width: 541px) {

	}
	@media (max-width: 540px) {

	}

}
</style>
