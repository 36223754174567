import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
import Home from '../views/Home.vue';
import Design from '../views/Design.vue';
import Lego from '../views/Lego.vue';
import Art from '../views/Art.vue';
import Contact from '../views/Contact.vue';

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/design',
		name: 'Design',
		component: Design,

	},
	{
		path: '/lego',
		name: 'Lego',
		component: Lego,
	},
	{
		path: '/art',
		name: 'Art',
		component: Art
	},
	{
		path: '/contact',
		name: 'Contact',
		component: Contact
	}
];

const router = new VueRouter({
	base: process.env.BASE_URL,
	routes
});

router.beforeEach((toRoute,fromRoute,next) => {
	if(toRoute.name === 'Home') {
		window.document.title = 'Ryan Howerter';
	} else {
   window.document.title = 'Ryan Howerter | ' + toRoute.name;
 }
   next();
})

export default router;
