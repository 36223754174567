<template>
	<!-- hero row -->
	<div class="home-page">
		<b-container fluid>
			<b-row class="desktopface">
				<b-col class="d-none d-md-block vw-35" cols="12" md="6"> </b-col>
				<b-col class="herologo" cols="12" md="6"><b-img fluid center :src="require('../assets/logo fancy.png')" alt="Ryan Howerter logo"></b-img></b-col>
				<b-col class="p-0 d-block d-md-none" cols="12"><b-img fluid center :src="require('../assets/bg A3.png')" alt="portrait of the artist in pink and blue lighting"></b-img></b-col>
			</b-row>
			<b-row class="ctarow item">
				<b-col class="ctahover p-0" cols="6" md="3"><b-link to="/design" exact exact-active-class="active"><b-img fluid center :src="require('../assets/CTA A.jpg')" alt="Design"></b-img></b-link></b-col>
				<b-col class="ctahover p-0" cols="6" md="3"><b-link to="/lego" exact exact-active-class="active"><b-img fluid center :src="require('../assets/CTA B.jpg')" alt="Lego"></b-img></b-link></b-col>
				<b-col class="ctahover p-0" cols="6" md="3"><b-link to="/art" exact exact-active-class="active"><b-img fluid center :src="require('../assets/CTA C.jpg')" alt="Art"></b-img></b-link></b-col>
				<b-col class="ctahover p-0" cols="6" md="3"><a href="#music" exact exact-active-class="active"><b-img fluid center :src="require('../assets/CTA D.jpg')" alt="Music"></b-img></a></b-col>
			</b-row>
		</b-container>

		<b-container fluid class="greenrow">
			<b-row style="margin:auto;">
				<b-carousel id="carousel-1" :interval="6000" controls indicators>
					<b-link to="/design" exact exact-active-class="active">
						<b-carousel-slide :img-src="require('../assets/slide 1.png')" alt="websites"></b-carousel-slide>
						<b-carousel-slide :img-src="require('../assets/slide 2.png')" alt="infographics"></b-carousel-slide>
						<b-carousel-slide :img-src="require('../assets/slide 3.png')" alt="posters"></b-carousel-slide>
					</b-link>
				</b-carousel>
			</b-row>
		</b-container>

		<b-container fluid class="purplerow">
			<b-row style="margin:auto;">
				<b-carousel id="carousel-2" :interval="6000" controls indicators>
					<b-link to="/design" exact exact-active-class="active">
						<b-carousel-slide :img-src="require('../assets/slide 6.png')" alt="red gate from spirited away"></b-carousel-slide>
						<b-carousel-slide :img-src="require('../assets/slide 5.png')" alt="record store"></b-carousel-slide>
						<b-carousel-slide :img-src="require('../assets/slide 4.png')" alt="goes-16"></b-carousel-slide>
					</b-link>
				</b-carousel>
			</b-row>
		</b-container>

		<b-container fluid class="bluerow">
			<b-row style="margin:auto;">
				<b-carousel id="carousel-3" :interval="6000" controls indicators>
					<b-link to="/design" exact exact-active-class="active">
						<b-carousel-slide :img-src="require('../assets/slide 8.png')" alt="spoon river"></b-carousel-slide>
						<b-carousel-slide :img-src="require('../assets/slide 7.png')" alt="ad astra"></b-carousel-slide>
						<b-carousel-slide :img-src="require('../assets/slide 9.png')" alt="reflections"></b-carousel-slide>
					</b-link>
				</b-carousel>
			</b-row>
		</b-container>

		<b-container fluid class="yellowrow" id="music">
			<b-row max-width="1080px" style="margin:auto;">
				<iframe class="soundcloud" width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/users/3658473&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
			</b-row>
		</b-container>
	</div>
</template>

<script>

export default {
	name: 'Home',
	props: {
		msg: {
			type: String,
			default: "this is a test message"
		}
	}
};

</script>

<style lang="scss">
.home-page {
	@media (min-width: 541px) {
		.desktopface {
			background-image: url('../assets/bg A1.png');
			background-attachment: scroll;
			background-position: bottom left;
			background-size: cover;
		}
		.herologo {
			margin-top: 220px;
			margin-bottom: 300px;
		}
		.greenrow .carousel {
			margin: 100px auto 100px auto;
		}
		.carousel {
			margin: 140px auto 100px auto;
		}
		.carousel-indicators {
			display: none;
		}
		.soundcloud {
			max-width: 1080px;
			margin: 140px auto 100px auto;
		}
	}
	@media (max-width: 540px) {
		.herologo {
			margin-top: 80px;
			margin-bottom: 30px;
		}
		.carousel {
			margin: 50px auto 40px auto;
		}
		.soundcloud {
			max-width: 1080px;
			margin: 50px auto 40px auto;
		}
		.carousel-control-prev, .carousel-control-next {
			display: none;
		}
	}
	.ctahover:hover {
		-webkit-filter: grayscale(0%);
		-moz-filter: grayscale(100%);
		-ms-filter: grayscale(100%);
		-o-filter: grayscale(100%);
		filter: grayscale(100%);
		transition-duration:.35s;
	}
	.greenrow {
		background-image: url('../assets/bg B.jpg');
		background-attachment: scroll;
		background-position: top center;
		background-size: cover;
	}
	.purplerow {
		background-image: url('../assets/bg C.jpg');
		background-attachment: scroll;
		background-position: top center;
		background-size: cover;
	}
	.bluerow {
		background-image: url('../assets/bg D.jpg');
		background-attachment: scroll;
		background-position: top center;
		background-size: cover;
	}
	.yellowrow {
		background-image: url('../assets/bg E.jpg');
		background-attachment: scroll;
		background-position: top center;
		background-size: cover;
	}
	.carousel-control-prev:hover, .carousel-control-next:hover {
		transform: translateY(-4px);
	}
	.carousel {
		max-width: 1080px;
	}
	.carousel-control-prev {
		width:auto;
		left: -50px;
	}
	.carousel-control-next {
		width:auto;
		right: -50px;
	}
	.carousel-indicators {
		bottom: -50px;
	}
	.carousel-indicators li {
		width: 10px;
		height: 10px;
		border-radius: 100%;
	}

}
</style>
