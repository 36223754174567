<template>
	<div class="contact-page">
		<b-container class="mainrow vh-100">

			<b-row class="titlerow d-block d-md-none" style="margin:auto;">
				<b-col cols="12"><b-img fluid center :src="require('../assets/contact.png')" alt="design title"></b-img></b-col>
			</b-row>

			<b-row class="itemrow" style="margin:auto;">
				<b-col class="" cols="12" md="6">
					<b-img class="shadow" fluid center :src="require('../assets/gbc.jpg')" alt="Selfie taken with a Game Boy Camera attached to a Super Game Boy attached to an SNES attached to my computer via a video capture card for a Zoom meeting"></b-img>
				</b-col>
				<b-col cols="12" md="6">
					<b-img class="d-none d-md-block" fluid left :src="require('../assets/line.png')" alt="line graphic"></b-img>
					<br/>
					<p>I am Ryan Howerter: designer, ingredient list speed-reader, lego-er, sometimes-musician. Currently based in Aurora, CO.</p>
					<p><a href="mailto:ryanhowerter@gmail.com">ryanhowerter@gmail.com</a></p>
					<p><a href="/HowerterResume.pdf">Resume</a></p>
					<p><a href="https://www.linkedin.com/in/ryan-howerter-72805991">LinkedIn</a></p>
				</b-col>
			</b-row>

		</b-container>
	</div>
</template>

<script>

export default {
	name: 'Contact',
};

</script>

<style lang="scss">
.contact-page {
	background-image: url('../assets/bg I.jpg');
	background-attachment: fixed;
	background-position: top center;
	background-size: cover;
}
.contact-page {
	a {
		color:#ec4fdf !important;
	}
	.titlerow {
		padding: 20px;
	}
	.itemrow {
		padding-top: 40px;
		padding-bottom: 40px;
	}

	@media (min-width: 541px) {

	}
	@media (max-width: 540px) {

	}

}
</style>
