<template>
	<div class="art-page">
		<b-container class="mainrow">

			<b-row class="titlerow d-block d-md-none" style="margin:auto;">
				<b-col cols="12"><b-img fluid center :src="require('../assets/art.png')" alt="design title"></b-img></b-col>
			</b-row>

			<b-row class="itemrow" style="margin:auto;">
				<b-col cols="12" md="4">
					<b-img fluid left :src="require('../assets/line.png')" alt="line graphic"></b-img>
					<br/>
					<p>Reflections</p>
					<p style="font-size:12px;">Each face was drawn as if it were a mirror, reflecting the drawing studio around it. Pencil on folded cardstock.</p>
				</b-col>
				<b-col cols="12" md="8">
					<b-carousel id="carousel-1" :interval="6000" indicators>
						<b-carousel-slide :img-src="require('../assets/reflections2.jpg')" alt="Reflections"></b-carousel-slide>
						<b-carousel-slide :img-src="require('../assets/reflections1.jpg')" alt="Reflections"></b-carousel-slide>
					</b-carousel>
				</b-col>
			</b-row>

			<b-row class="itemrow" style="margin:auto;">
				<b-col cols="12" md="4">
					<b-img fluid center :src="require('../assets/line.png')" alt="line graphic"></b-img>
					<br/>
					<p>Family Portrait</p>
					<p style="font-size:12px;">I love looking through found photographs and trying to piece together a narrative. This is a visual representation of those invented connections among people I've never met and places I've never been.</p>
					<p style="font-size:12px;">Photographic prints, torn and stitched with gold thread.</p>
				</b-col>
				<b-col cols="12" md="8">
					<b-carousel id="carousel-2" :interval="6000" indicators>
						<b-carousel-slide :img-src="require('../assets/memories2.jpg')" alt="Family Portrait"></b-carousel-slide>
						<b-carousel-slide :img-src="require('../assets/memories1.jpg')" alt="Family Portrait"></b-carousel-slide>
					</b-carousel>
				</b-col>
			</b-row>

			<b-row class="itemrow" style="margin:auto;">
				<b-col cols="12" md="4">
					<b-img fluid center :src="require('../assets/line.png')" alt="line graphic"></b-img>
					<br/>
					<p>Spoon River</p>
					<p style="font-size:12px;">The first tenth or so of <a href="https://en.m.wikipedia.org/wiki/Spoon_River_Anthology">Spoon River Anthology</a>, written out by hand. It's just a <a href="https://en.wikipedia.org/wiki/River_(typography)">pun</a>.</p>
				</b-col>
				<b-col cols="12" md="8">
					<b-carousel id="carousel-3" :interval="6000" indicators>
						<b-carousel-slide :img-src="require('../assets/spoon1.jpg')" alt="Spoon River"></b-carousel-slide>
						<b-carousel-slide :img-src="require('../assets/spoon2.jpg')" alt="Spoon River"></b-carousel-slide>
					</b-carousel>
				</b-col>
			</b-row>

			<b-row class="itemrow" style="margin:auto;">
				<b-col cols="12" md="4">
					<b-img fluid center :src="require('../assets/line.png')" alt="line graphic"></b-img>
					<br/>
					<p>Ad Astra</p>
					<p style="font-size:12px;">White paint pen and gesso, ink on acetate, watercolors on paper.</p>
				</b-col>
				<b-col cols="12" md="8">
						<b-img class="shadow" fluid center :src="require('../assets/adastra1.jpg')" alt="Ad Astra"></b-img>
				</b-col>
			</b-row>

		</b-container>
	</div>
</template>

<script>

export default {
	name: 'Art',
};

</script>

<style lang="scss">
.art-page {
	background-image: url('../assets/bg H.jpg');
	background-attachment: fixed;
	background-position: top center;
	background-size: cover;
}
.art-page {
	.carousel-indicators {
		bottom: -50px;
	}
	.carousel-indicators li {
		width: 10px;
		height: 10px;
		border-radius: 100%;
		background-color:#93f1ff;
	}
	a {
		color:#5bd1e2;
	}
	.titlerow {
		padding: 20px;
	}
	.itemrow {
		padding-top: 40px;
		padding-bottom: 40px;
	}

	@media (min-width: 541px) {
		#carousel-1 {
			min-height: 676px;
		}
		#carousel-2 {
			min-height: 533px;
		}
		#carousel-3 {
			min-height: 710px;
		}
	}
	@media (max-width: 540px) {
		#carousel-1 {
			min-height: 300px;
		}
		#carousel-2 {
			min-height: 237px;
		}
		#carousel-3 {
			min-height: 315px;
		}
	}

}
</style>
