<template>
  <div class="header">
    <b-navbar sticky toggleable="md" type="light" variant="light">
      <b-navbar-brand to="/"><img class="logo" alt="Ryan Howerter logo" src="../assets/logo.png"></b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto navigation">
          <b-nav-item class="green" to="/design" exact exact-active-class="active">Design</b-nav-item>
          <b-nav-item class="purple" to="/lego" exact exact-active-class="active">Lego</b-nav-item>
          <b-nav-item class="blue" to="/art" exact exact-active-class="active">Art</b-nav-item>
          <b-nav-item class="yellow" to="/contact" exact exact-active-class="active">Contact</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    msg: String
  },
}
</script>


<style lang="scss">
.header {

  .logo {
    max-width:200px;
  }
  .nav-link {
    color: #000000 !important;
  }
  .green .nav-link:hover, .green .nav-link.active {
    color:#71e09d !important;
  }
  .purple .nav-link:hover, .purple .nav-link.active {
    color:#ec4fdf !important;
  }
  .blue .nav-link:hover, .blue .nav-link.active {
    color:#5bd1e2 !important;
  }
  .yellow .nav-link:hover, .yellow .nav-link.active {
    color:#efd718 !important;
  }
  .navigation {
    text-align:right;
  }

  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  .navbar {
    box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.2);
  }

}
</style>
