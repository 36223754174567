<template>
	<div class="lego-page">
		<b-container class="mainrow">

			<b-row class="titlerow d-block d-md-none" style="margin:auto;">
				<b-col cols="12"><b-img fluid center :src="require('../assets/lego.png')" alt="design title"></b-img></b-col>
			</b-row>

			<b-row class="itemrow" style="margin:auto;">
				<b-col cols="12" md="6">
					<b-img fluid left :src="require('../assets/line.png')" alt="line graphic"></b-img>
					<br/>
					<p>A diorama of the red gate at the beginning of Spirited Away, the Studio Ghibli movie. This ~3'x2' scene was an experiment in new foliage techniques and creating visual depth.</p>
					<p style="font-size:12px;">屋 油 湯</p>
				</b-col>
				<b-col cols="12" md="6">
					<b-img class="shadow" fluid center :src="require('../assets/redgate.jpg')" alt="Red Gate from Spirited Away"></b-img>
				</b-col>
			</b-row>

			<b-row class="itemrow" style="margin:auto;">
				<b-col cols="12" md="4">
					<b-img fluid center :src="require('../assets/line.png')" alt="line graphic"></b-img>
					<br/>
					<p>I built this to capture the feel of your favorite record store — all of the printed album covers are original Lego tiles, nothing is custom-printed. This photo was used for the fold-out poster in the vinyl version of The LEGO<sup>®</sup> Movie soundtrack, and earned my name a place in the credits of the movie itself.</p>
					<p style="font-size:12px;"></p>
				</b-col>
				<b-col cols="12" md="8">
					<b-img class="shadow" fluid center :src="require('../assets/recordstore.jpg')" alt="Lego Record Store"></b-img>
				</b-col>
			</b-row>

			<b-row class="itemrow" style="margin:auto;">
				<b-col cols="12" md="4">
					<b-img fluid center :src="require('../assets/line.png')" alt="line graphic"></b-img>
					<br/>
					<p>Arsia Prime, my vision for a near-future colony on mars. In planning this 6' build, I researched everything from science fiction concept art to real-world proposals for our first habitable structures on mars. I also created my own <a href="https://soundcloud.com/ryan-h-3/arsiaprime?si=bb5af83098f542b490429e0a7d69938e">electronic background music</a>, a <a href="https://www.flickr.com/photos/ltdemartinet/35249685305">teaser video</a>, a logo, and even a custom font to go along with the build. I love a good multimedia project!</p>
					<p style="font-size:12px;">This model is in the permanent collection of the Blocks to Bricks museum in Schaumburg, IL</p>
					<b-img fluid left :src="require('../assets/arsialogos.png')" alt="Arsia Prime logo"></b-img>
				</b-col>
				<b-col cols="12" md="8">
					<b-carousel id="carousel-1" :interval="4000" indicators>
						<b-carousel-slide :img-src="require('../assets/arsia1.jpg')" alt="Arsia Prime Mars diorama"></b-carousel-slide>
						<b-carousel-slide :img-src="require('../assets/arsia2.jpg')" alt="Arsia Prime Mars diorama"></b-carousel-slide>
						<b-carousel-slide :img-src="require('../assets/arsia3.jpg')" alt="Arsia Prime Mars diorama"></b-carousel-slide>
						<b-carousel-slide :img-src="require('../assets/transparent.png')" alt="Arsia Prime Mars diorama"></b-carousel-slide>
					</b-carousel>
				</b-col>
			</b-row>

			<b-row class="itemrow" style="margin:auto;">
				<b-col cols="12" md="4">
					<b-img fluid center :src="require('../assets/line.png')" alt="line graphic"></b-img>
					<br/>
					<p>The NASA/NOAA weather research satellite GOES-16 is currently orbiting earth, collecting and transmitting space data. Exciting!</p>
					<p style="font-size:12px;">Commissioned by the University of Colorado Boulder</p>
				</b-col>
				<b-col cols="12" md="8">
					<b-img class="shadow" fluid center :src="require('../assets/goes.jpg')" alt="GOES-16 Weather Satellite"></b-img>
				</b-col>
			</b-row>

			<b-row class="itemrow" style="margin:auto;">
				<b-col cols="12" md="4">
					<b-img fluid center :src="require('../assets/line.png')" alt="line graphic"></b-img>
					<br/>
					<p>Cataloging and tracking the history of Lego colors is an ongoing pet project of mine. I have created several resources for the Lego fan community, including <a href="https://www.flickr.com/photos/126975831@N07/albums/72157649729890049">individual photos</a> of every color, a <a href="https://www.flickr.com/photos/126975831@N07/15801189140">timeline</a> showing when colors were introduced and discontinued, and a <a href="https://www.ryanhowerter.net/colors">complete list</a> of colors, which cross-references names across different fan sites.</p>
					<p style="font-size:12px;"></p>
				</b-col>
				<b-col cols="12" md="8">
					<b-img class="shadow" fluid center :src="require('../assets/colors.jpg')" alt="2x2 tiles Lego color chart"></b-img>
				</b-col>
			</b-row>

			<b-row class="itemrow" style="margin:auto;">
				<b-col cols="12" md="6">
					<b-img fluid center :src="require('../assets/line.png')" alt="line graphic"></b-img>
					<br/>
					<p>The Reverse Engineering Challenge — I <a href="https://www.instagram.com/ryan_howerter/">posted</a> a small puzzle build every single day for 150+ days in 2020, and the challenge was to figure out how each day's build was constructed. I ran this as a contest a few times as early as 2010, but given the pandemic I wanted something more casual that people could solve at their own pace. These puzzles, designed by me and others, ended up giving thousands of people a fun distraction each day during an uncertain period of being stuck at home.</p>
					<p style="font-size:12px;">Right: puzzles by myself, Mikael Montelius, Oscar Cederwall, & Jack Porter</p>
				</b-col>
				<b-col cols="12" md="6">
					<b-img class="shadow" fluid center :src="require('../assets/collage.jpg')" alt="Reverse Engineering Challenge builds"></b-img>
				</b-col>
			</b-row>

			<b-row class="itemrow" style="margin:auto;">
				<b-col cols="12">
					<p style="font-size:12px; text-align:right;">I am not affiliated with The LEGO<sup>®</sup> Group. If you are a recruiter who would like to change that, <b-link to="/contact" exact exact-active-class="active">hit me up</b-link>!</p>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>

export default {
	name: 'Lego',
};

</script>

<style lang="scss">
.lego-page {
	background-image: url('../assets/bg G.jpg');
	background-attachment: fixed;
	background-position: top center;
	background-size: cover;
}
.lego-page {
	.carousel-indicators {
		bottom: -50px;
	}
	.carousel-indicators li {
		width: 10px;
		height: 10px;
		border-radius: 100%;
		background-color:#ec4fdf;
	}
	a {
		color:#ec4fdf;
	}
	.titlerow {
		padding: 20px;
	}
	.itemrow {
		padding-top: 40px;
		padding-bottom: 40px;
	}

	@media (min-width: 541px) {

	}
	@media (max-width: 540px) {

	}

}
</style>
